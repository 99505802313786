import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
 var prevScrollpos = window.pageYOffset;
 const navBar = document.getElementById("navbar");

 window.onscroll = function() {
   var currentScrollPos = window.pageYOffset;

   if (prevScrollpos > currentScrollPos ||  window.pageYOffset < 50) {
     navBar.classList.remove("show-nav");
   } else {
     navBar.classList.add("show-nav");
   }

   if(window.pageYOffset < 50) {
     navBar.classList.remove("white-nav");
   } else {
     navBar.classList.add("white-nav");
   }

   prevScrollpos = currentScrollPos;



 }

 $("#dynamic_form").on({
  submit: function () {
    var $self = $(this);
    $(".alert.alert-success").remove();
    $("button[type=submit]", $self).prop("disabled", true);

    $.ajax({
      type: "post",
      dataType: "json",
      data: $(this).serialize(),
      success: function (response) {
        $("ul.errors.help-block", $self).remove();
        $(".has-error", $self).removeClass("has-error");

        if (response.success && response.finished) {
          $self[0].reset();

          // var $successMessage = $("<div>", { "class": "alert alert-success" })
          //   .append("<p>Form submitted successfully</p>", { "class": "lead" });

          // $self.before($successMessage);
          $("#contactFormFields").hide();
          $("#contactFormSuccess").fadeIn();
          $("#contactFormSuccess")[0].scrollIntoView();


        } else if (response.errors) {
          for (var key in response.errors) {
            if (!response.errors.hasOwnProperty(key)) continue;
            var messages = response.errors[key];
            var $errors = $("<ul>", { "class": "errors help-block" });
            for (var i = 0; i < messages.length; i++) {
              var $li = $("<li>");
              $li.html(messages[i]);
              $errors.append($li);
            }

            var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

            const $formGroup = $input.parents(".form-group");
            $formGroup.addClass("has-error");

            $formGroup.append($errors);
          }
        }

        if (response.honeypot) {
          var honeypotInput = $("input[name^=freeform_form_handle_]", $self);
          honeypotInput.attr("name", response.honeypot.name);
          honeypotInput.attr("id", response.honeypot.name);
          honeypotInput.val(response.honeypot.hash);
        }

        $("button[type=submit]", $self).prop("disabled", false);
      },
    });

    return false;
  },
});
